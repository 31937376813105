<template>
  <div>
    
    <vx-card class="tabulator_card">
       <!-- v-if="user_id == 132 || user_id == 937 || user_id == 12" -->
      <vs-row v-if="this.candidates_data[0].mwb_id != null" class="mb-3">
        <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start">
          <treeselect
            v-model="selected_column"
            :multiple="true"
            :options="treeDataHide"
            valueFormat='object'
            placeholder="Hide Column"
          />
        </vs-col>
        <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <vs-button color="#0044BA"
          size="small" @click="HideColumn">
            Hide - Unhide Columns
          </vs-button>
        </vs-col>
        <!-- <vs-col vs-w="3" vs-offset="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
          <vs-button
          color="#0044BA"
          icon="file_download"
          size="small"
          @click="downloadCvsTable"
          ref="loadableButton"
          id="button-with-loading"
          class="vs-con-loading__container"
          v-if="show_btn"
          >Download</vs-button
        >
        </vs-col> -->
      </vs-row> 
      <vs-row v-if="this.candidates_data[0].mwb_id != null" style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" class="mb-6">
          <b>Search Criteria:</b>
        </vs-col>
        <vs-col
          style="overflow-y: scroll"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
          class="mb-6"
        >
          <div v-for="(chip, index) in searchCriteria" :key="index">
            <vs-chip
              v-if="chip !== 'divider'"
              closable
              @click="removechip(chip)"
              color="#0044BA"
            >
              <b>{{ chip }}</b>
            </vs-chip>
            <div v-else>
              <div class="vl"></div>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <div
        style="display: flex; justify-content: end"
        class="mb-2"
      >
        
      </div>
      <!-- <div style="display:flex;justify-content:center"> -->
      <div v-if="this.candidates_data[0].mwb_id != null" ref="table1" id="example-table-theme" ></div>
      <div v-else ref="table1" id="example-table-theme" style="max-width:95vh;"></div>
      <!-- </div> -->
    </vx-card>
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
// import eventbus from '../components/eventbus';
import Treeselect from "@riophae/vue-treeselect";
export default {
  components: {
    Treeselect,
  },
  props: ["candidates_data"],
  mounted() {
    this.getCandidatesTable();
  },
  watch: {
    // tabulatorDetails(val) {
    //   this.getCandidatesTable();
     
    // },
    selected_column:function(){
      this.getSelectedColumns();
    },
  },
  data() {
    return {
      searchCriteria: [],
      show_btn:false,
      backgroundLoading:'primary',
      colorLoading:'#fff',
    //   MwbLeads: [],
      user_id: "",
      treeDataHide:[
        {
          label: "Candidate Name",
          id: "person_name",
        },
        {
          label: "Company",
          id: "company",
        },
         {
          label: "Experience",
          id: "experience",
        },
        {
          label: "Courses",
          id: "courses",
        },
        {
          label: "M Level",
          id: "level",
        },
        {
          label: "U Level",
          id: "iiml_level",
        },
        // {
        //   label: "Last Created",
        //   id: "lead_created_date",
        // },
        {
          label: "Comment",
          id: "processing_comments",
        },
        {
          label: "SPOC Name",
          id: "spoc_name",
        },
        {
          label: "SR SPOC Name",
          id: "sr_name",
        },
        {
          label: "Lead Created Date",
          id: "lead_created_date",
        },
         {
          label: "MHP Date",
          id: "mhp_date",
        },
        {
          label: "Last Call",
          id: "last_call",
        },
        {
          label: "Next Call",
          id: "next_call",
        },
        {
          label: "FAM Date",
          id: "fam_lam.first_enquiry_date",
        },
         {
          label: "LAM Date",
          id: "fam_lam.last_enquiry_date",
        },
        {
          label: "About",
          id: "info",
        },
      ],
      selected_column:[],
    };
  },
  methods: {
    getSelectedColumns(){
      console.log(this.selected_column)
      this.searchCriteria = [];
        for (let k = 0; k < this.selected_column.length; k++) {
          this.searchCriteria.push(this.selected_column[k].label)
        }
        console.log("find selected columns",this.searchCriteria)
    },
    removechip(chip){
      // this.selected_column.splice(
      //     this.selected_column.indexOf(chip),
      //     1
      //   );
      let index = this.selected_column.findIndex(x => x.label ===chip);
      this.selected_column.splice(index, 1);
      this.searchCriteria.splice(this.searchCriteria.indexOf(chip), 1);
      // console.log("ghgytuy",chip, this.treeDataHide[0]);
      // if (this.selected_column.indexOf(chip) === -1) {
      //   this.selected_column = [];
      //   this.treeDataHide.forEach((child) => {
      //     if (child.label !== chip) {
      //       this.selected_column.push(child.label);
      //     }
      //   });
      // } else {
      //   this.selected_column.splice(
      //     this.selected_column.indexOf(chip),
      //     1
      //   );
      // }
    },
    
    getCandidatesTable() {
      console.log("fgfhtruytikuyo", this.candidates_data[0].id, this.candidates_data[0].mwd_id );
      if(this.candidates_data[0].mwb_id != null){
        this.tabulator = new Tabulator(this.$refs.table1, {
          maxHeight: "80vh",
          data: this.candidates_data,
          // layout:"fitColumns",
          // layout:"fitData",
          rowFormatter: (function(row) {
          var evenColor = "#F5F6FA";
          var oddColor = "#FFF";
          row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
          }),
          columns: [
            {
              title: "Can-ID",
              field: "identity",
              frozen: true,
              sorter: "alphanum",
              // width:200,
            },
            {
              title: "Candidate Name",
              frozen: true,
              field: "person_name",
              // width:200,
            },
            {
              title: "Company",
              field: "company",
              sorter: "alphanum",
              // width:200,
            },
            {
              title: "Experience",
              field: "experience",
              sorter: "alphanum",
              // width:200,
            },
            {
              title: "Courses",
              field: "courses",
              sorter: "alphanum",
              // width:200,
            },
            {
              title: "M Level",
              field: "level",
              sorter: "alphanum",
              // width:200,
            },
            {
              title: "U Level",
              field: "iiml_level",
              sorter: "alphanum",
              // width:200,
            },
            // {
            //   title: "Last Created Date",
            //   field: "lead_created_date",
            //   sorter: "alphanum",
            //   // width:200,
            // },
          
            {
              title: "SPOC Name",
              field: "spoc_name",
              sorter: "alphanum",
              // width:200,
            },
            {
              title: "SR SPOC Name",
              field: "sr_name",
              sorter: "alphanum",
              // width:200,
            },
            {
              title: "Lead Created Date",
              field: "lead_created_date",
              sorter: "alphanum",
              // width:200,
            },
            {
              title: "MHP Date",
              field: "mhp_date",
              sorter: "alphanum",
              // width:200,
            },
            {
              title: "Last Call",
              field: "last_call",
              sorter: "alphanum",
              // width:200,
            },
            {
              title: "Next Call",
              field: "next_call",
              sorter: "alphanum",
              // width:200,
            },
            {
              title: "FAM Date",
              field: "fam_lam.first_enquiry_date",
              sorter: "alphanum",
              // width:200,
            },
            {
              title: "LAM Date",
              field: "fam_lam.last_enquiry_date",
              sorter: "alphanum",
              // width:200,
            },
            {
              title: "Comment",
              field: "processing_comments",
              sorter: "alphanum",
              maxWidth:300,
            },
            {
              title: "About",
              field: "info",
              headerSort: false,
              formatter: "image",
              // minWidth:200,
              hozAlign: "center",
              formatterParams: {
                  height: "25px",
                  width: "25px",
              },
              cellClick: this.getId,
            },
          ],
        });
      } 
    //  if(this.candidates_data[0].mwd_id == null){
     else {
        this.tabulator = new Tabulator(this.$refs.table1, {
          data: this.candidates_data,
          layout:"fitColumns",
          rowFormatter: (function(row) {
          var evenColor = "#F5F6FA";
          var oddColor = "#FFF";
          row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
          }),
          columns: [
            {
              title: "Candidate Name",
              field: "username",
            },
            {
              title: "Mobile Number",
              field: "phone",
            },
            {
              title: "Email",
              field: "email",
            },
            {
              title: "City",
              field: "city",
            },
          ],
        });
      } 
    },
    HideColumn(){
      console.log("fkjghnitji",this.selected_column, this.treeDataHide);
      this.treeDataHide.forEach((treeData) => {
        this.tabulator.showColumn(treeData.id)
      });
      if(this.selected_column.length > 0){
        this.selected_column.forEach((selected_item)=>{
          this.treeDataHide.forEach((item) =>{
            console.log("kjfgirjhytioryjh",item.id, selected_item);
              if(item.id == selected_item.id){
                this.tabulator.hideColumn(item.id)
              }
            })
          })
      }
    },
    getId(e, cell) {
      console.log(e, cell._cell.row.data.id);
      this.openBigPopup(cell._cell.row.data.id);
    },
   
  },
};
</script>

<style>
.tabulator_card{
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}
.tabulator {
  background-color: #f2f2f2;
}

.tabulator .tabulator-header {
  background-color: #f2f2f2;
  /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(odd) {
  background-color: #f2f2f2;
  color: rgb(0, 0, 0) !important;
  /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(even) {
  background-color: #e4e1e1;
  color: rgb(0, 0, 0) !important;
  /* padding-block: 5px; */
}

.tabulator .tabulator-header .tabulator-col {
  text-align: center;
}

.tabulator-calcs-bottom .tabulator-cell {
  background-color: rgb(0, 0, 0);
  color: #f2f2f2 !important;
}
</style>