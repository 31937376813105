<template>
  <div>
    <div class="filter-card-wd">
      <vs-row>
        <vs-col
          vs-w="10"
          vs-type="flex"
          vs-justify="flex-start"
          style="padding: 0px"
        >
          <div class="wd-heading">
            <p class="wd-text">Webinar Dashboard</p>
            <!-- <p class="wd-text">{{visits_count}}</p> -->
          </div>
        </vs-col>
        <!-- <vs-col
          vs-w="2"
          vs-type="flex"
          vs-justify="flex-end" 
          style="margin-top: 1%;"
          v-if="show_upload_btn && (logged_in_id == 937 || logged_in_id == 132)"
        >
          <vs-button color="#0044ba" @click="uploadWebinarData">
            Upload
          </vs-button>
        </vs-col> -->
      </vs-row>
      <div v-if="show_alert_message" class="alert_message">
        {{ alert_message }}
      </div>
      <vs-row class="mt-6">
        <vs-col
          vs-w="1"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <p class="filter-heading">Filters</p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              Webinar
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="selected_webinar"
                :options="webinars"
                valueFormat="object"
              />
              <!-- <v-select v-model="selected_webinar" :options="webinars"></v-select> -->
              <!-- <v-select v-model="selected_webinar" :options="webinars">
                <template slot="option" slot-scope="option">
                    <vx-tooltip style="position: inherit;  z-index: 100000" :text="option.label">{{ option.label }}</vx-tooltip>
                </template>
              </v-select> -->
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <!-- <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-stats"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              Webinar Stats
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="selected_stat"
                :options="webinar_stats_tree_data"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col> -->
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-team"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              Team
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                :disabled="disabled"
                v-model="init_selected_team"
                :multiple="true"
                :options="treeTeam"
                :value-consists-of="'LEAF_PRIORITY'"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-city"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              City
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedCity"
                :multiple="true"
                :options="treeDataCity"
                :value-consists-of="'LEAF_PRIORITY'"
                valueFormat="object"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="gm"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              GM
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedGM"
                :multiple="true"
                valueFormat="object"
                :value-consists-of="'LEAF_PRIORITY'"
                :options="treeDataGM"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <div style="display: inline-grid; width: 100%; margin-top: -10%;">
              <!-- <label class="date_filter_heading">Date</label> -->
              <label class="date_filter_heading">Enrollment month and year</label>
              <datepicker
                placeholder="MM/YY"
                :minimumView="'month'"
                :maximumView="'month'"
                class="webinar-my-date-picker"
                :format="customFormatter"
                v-model="dates"
              ></datepicker>
            </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-offset="0.1"
          vs-w="0.7"
        >
          <div>
            <vs-button
              color="#0044ba"
              icon="clear"
              @click="clearDateFilter"
              size="small"
            ></vs-button>
          </div>
        </vs-col>
        <!--
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-course"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              Course
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedCity"
                :multiple="true"
                :options="treeDataCity"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-city"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              City
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedCity"
                :multiple="true"
                :options="treeDataCity"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-gm"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              GM
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedCity"
                :multiple="true"
                :options="treeDataCity"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col> -->
      </vs-row>
      <vs-row class="mt-2">
        <vs-col
          vs-w="1"
        >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-course"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              Course
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedCourse"
                :multiple="true"
                :options="treeDataCourses"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="spoc"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text py-1"
              href.prevent
              @click="showTreeBox"
            >
              SPOC
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedSpoc"
                :value-consists-of="'LEAF_PRIORITY'"
                valueFormat="object"
                :multiple="true"
                :options="treeDataSpoc"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-topic"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              Topic
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedtopic"
                :options="treeDataTopics"
                valueFormat="object"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-levels"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              Level
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="selected_level"
                :options="treeDataLevels"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="macc_level_drop"
          v-if="selectedLevels != null && selectedLevels.toString() == 'm_level'"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              CM Level
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedCMLevel"
                :multiple="true"
                :options="treeDataCMLevel"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="macc_level_drop"
          v-if="selectedLevels != null && selectedLevels.toString() == 'u_level'"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
            USP Level
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="init_selected_iiml_levels"
                :multiple="true"
                :options="macc_levels"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
         
      </vs-row>
      <!-- <vs-row class="mt-4">
        <vs-col
          vs-w="1"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-spoc"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              SPOC
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedCity"
                :multiple="true"
                :options="treeDataCity"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-level"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              Level
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedCity"
                :multiple="true"
                :options="treeDataCity"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-date"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              Date
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedCity"
                :multiple="true"
                :options="treeDataCity"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-topic"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              Topic
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedCity"
                :multiple="true"
                :options="treeDataCity"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
      </vs-row> -->
      <vs-row class="mt-6 mb-2">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="12"
        >
          <div class="wd-search-criteria mt-3">
            <h6 class="wd-criteria-heading ml-2 mt-2">Search Criteria:</h6>
            <vs-row vs-w="12" class="mt-2">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
                vs-offset="0.1"
                vs-w="10.7"
              >
                <div style="display: inline-block; width: 100%">
                  <span>
                    <vs-chip
                      v-if="remove_webinar != ''"
                      @click="removechip(remove_webinar, 'webinar')"
                      class="criteria_chip"
                      closable
                      color="#f1ad78"
                    >
                      <b>{{ remove_webinar }}</b>
                    </vs-chip>
                  </span>
                  <!-- <span>
                    <vs-chip
                      v-if="selected_stat != null && selected_stat != undefined"
                      @click="removechip(selected_stat, 'webinar_stat')"
                      class="criteria_chip"
                      closable
                      color="#fae1fe"
                    >
                      <b>{{ selected_stat }}</b>
                    </vs-chip>
                  </span> -->
                  <span v-for="chip in selected_teams" :key="chip">
                    <vs-chip
                      @click="removechip(chip, 'team')"
                      class="criteria_chip"
                      closable
                      color="#caecc5"
                    >
                      <b>{{ chip }}</b>
                    </vs-chip>
                  </span>
                   <span v-for="chip in selected_city" :key="chip">
                    <vs-chip
                      @click="removechip(chip, 'city')"
                      class="criteria_chip"
                      closable
                      color="#eae5fa"
                    >
                      <b>{{ chip }}</b>
                    </vs-chip>
                  </span>
                  <span v-for="chip in selected_gm_spocs" :key="chip">
                    <vs-chip
                      @click="removechip(chip, 'gm')"
                      class="criteria_chip"
                      closable
                      color="#c2fff0"
                    >
                      <b>{{ chip }}</b>
                    </vs-chip>
                  </span>
                   <span v-for="chip in selected_spocs" :key="chip">
                    <vs-chip
                      @click="removechip(chip, 'spoc')"
                      class="criteria_chip"
                      closable
                      color="rgba(188, 235, 250, 0.69)"
                    >
                      <b>{{ chip }}</b>
                    </vs-chip>
                  </span>
                  <span v-for="chip in selectedCourseData" :key="chip">
                    <vs-chip
                      class="criteria_chip"
                      closable
                      @click="removechip(chip, 'course')"
                      color="#FFC2C2"
                    >
                      <b>{{ chip }}</b>
                    </vs-chip>
                  </span>
                   <!-- <span v-for="chip in selectedTopicData" :key="chip">
                    <vs-chip
                      class="criteria_chip"
                      closable
                      @click="removechip(chip, 'topic')"
                      color="#ffcdf1"
                    >
                      <b>{{ chip }}</b>
                    </vs-chip>
                  </span> -->
                   <span>
                    <vs-chip
                    v-if="selectedTopicData != ''"
                      class="criteria_chip"
                      closable
                      @click="removechip(selectedTopicData, 'topic')"
                      color="#ffcdf1"
                    >
                      <b>{{ selectedTopicData }}</b>
                    </vs-chip>
                  </span>
                  <span v-if="selectedLevels != null">
                    <vs-chip class="criteria_chip" closable
                        @click="removechip(selectedLevels.toString(), 'level')"
                        style="color:#000000; background: #C2FFC8">
                        <b v-if="selectedLevels.toString() == 'u_level'">USP Level</b>
                        <b v-else>CM Level</b>
                    </vs-chip>
                </span>
                <span v-for="chip in selected_cm" :key="chip.id">
                  <vs-chip class="criteria_chip"
                      style="color:#000000; background: rgb(255,213,213)"
                      @click="removechip(chip, 'cm_level')" closable>
                      <b>{{ chip }}</b>
                  </vs-chip>
                </span>
                <span v-for="chip in selectedMAccLevels" :key="chip.id">
                  <vs-chip class="criteria_chip"
                      style="color:#000000; background: rgb(255,213,213)"
                      @click="removechip(chip, 'Mlevel')" closable>
                      <b>{{ chip }}</b>
                  </vs-chip>
                </span>
                  
                </div>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="flex-start"
                vs-w="1.2"
              >
                <vs-button
                  color="#0044ba"
                  icon="search"
                  @click="searchWebinar()"
                  size="small"
                ></vs-button>
                &nbsp;
                <vs-button
                  color="#0044ba"
                  icon="refresh"
                  @click="refreshData()"
                  size="small"
                ></vs-button>
                &nbsp;
                <!-- <vs-button
                  color="#0044ba"
                  icon="refresh"
                  @click="refreshBdeLeadsData()"
                  size="small"
                ></vs-button> -->
              </vs-col>
              
            </vs-row>
          </div>
        </vs-col>
      </vs-row>
    </div>
    <br />
    <div v-if="stats_names.length != 0" class="filter-card-wd">
      <!-- <vs-row vs-w="12">
        <vs-col vs-w="12"> -->
      <br />
      <vs-row>
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          class="desc-border-box2 mb-8"
        >
          <p style="text-align: center">Webinar Stats</p>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col
          v-for="item in stats_names"
          :key="item"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <div @click="getStatData(item)" class="webinar-stats-box">
            <p v-if="item == 'Pre Enrolled'">Enrolled Prior To Webinar</p>
            <p v-else-if="item == 'Post Enrolled'">Enrolled Post Webinar</p>
            <p v-else>{{ item }}</p>
            <p>{{ stats[item] }}</p>
          </div>
        </vs-col>
      </vs-row>
      <!-- </vs-col>
       </vs-row> -->
      <!-- <vs-row>
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-center"
          vs-align="flex-center"
        >
        <div class="wd-second-heading">
           <div class="wd-second-heading-text">
            Webinar Stats
           </div>
        </div>  
        </vs-col>
      </vs-row> -->
    </div>
    <br />
    <div v-if="stat_wise_gm_data.length > 0 && selected_stat != null" class="filter-card-wd">
      <vs-row>
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          class="desc-border-box3 mb-8"
        >
          <p style="text-align: center">{{ selected_stat }}</p>
        </vs-col>
      </vs-row>
      <!-- <vs-row class="mt-4">
        <vs-col vs-w="1" vs-type="flex" vs-justify="center">
          <p class="filter-heading">Filters</p>
        </vs-col> -->
        <!-- <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-city"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              City
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedCity"
                :multiple="true"
                :options="treeDataCity"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col> -->
        <!-- <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-gm"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              GM
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedCity"
                :multiple="true"
                :options="treeDataCity"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col> -->
        <!-- <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="wd-tree-drop"
          id="webinar-level"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a
              class="a-icon a-iconns ele-inside-text alignment py-1"
              href.prevent
              @click="showTreeBox"
            >
              Level
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
              <treeselect
                v-model="initSelectedCity"
                :multiple="true"
                :options="treeDataCity"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col> -->
      <!-- </vs-row> -->
      <vs-row  class="table-overflow">
        <vs-col vs-w="16" vs-type="flex" vs-justify="center">
          <table class="rwd-table">
            <thead>
              <tr>
                <th>GM City</th>
                <th>GM</th>
                <th>Total</th>
                <th v-for="level in levels_list" :key="level">
                  {{ level }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in stat_wise_gm_data" :key="item.gm">
                <td>
                  {{ item.gm_city }}
                </td>
                <td>
                  {{ item.gm_name }}
                </td>
                <td @click="checkGM(item, 'all')">
                  {{ item.total }}
                </td>
                <td v-for="level in item.gm_levels" :key="level.level">
                  <p @click="getCellData(item, level)">
                    {{ level.level_count }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </vs-col>
      </vs-row>
    </div>
    <br />
    <!-- <div v-if="show == true && selected_cell.length != 0" class="filter-card-wd"> -->
    <div v-if="gm_level_wise_spoc_data != ''" class="filter-card-wd">
      <vs-row>
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          class="desc-border-box3 mb-8"
        >
          <p style="text-align: center">{{ selected_stat }}</p>
        </vs-col>
      </vs-row>
      <vs-row  class="table-overflow">
        <vs-col vs-w="16" vs-type="flex" vs-justify="center">
          <table class="rwd-table">
            <!-- <thead v-if="selected_gm_level == 'all'">
              <tr>
                <th>GM</th>
                <th>SPOC</th>
                <th>SPOC Total</th>
                <th v-for="level in levels_list" :key="level">
                  {{ level }}
                </th>
              </tr>
            </thead>
            <thead v-if="selected_gm_level != 'all'">
              <tr>
                <th>GM</th>
                <th>SPOC</th>
                <th>
                  {{ selected_gm_level.level }}
                </th>
              </tr>
            </thead>
            <tbody v-if="selected_gm_level == 'all'">
              <tr v-for="item in selected_cell.spocs" :key="item.spoc_id">
                <td>{{selected_cell.gm_name}}</td>
                <td>
                  {{ item.spoc_name }}
                </td>
                <td>
                  <p
                    @click="getCandidateDetailsWithGmWiseWebinars(item, 'All')"
                  >
                    {{ item.spoc_total }}
                  </p>
                </td>

                <td v-for="level in item.levels" :key="level.level">
                  <p
                    @click="
                      getCandidateDetailsWithGmWiseWebinars(item, level.level)
                    "
                  >
                    {{ level.level_count }}
                  </p>
                </td>
                
              </tr>
            </tbody>
            <tbody v-if="selected_gm_level != 'all'">
              <tr v-for="item in selected_cell.spocs" :key="item.spoc_id">
                <td>{{selected_cell.gm_name}}</td>
                <td>
                  {{ item.spoc_name }}
                </td>
                <td>
                  <p
                    @click="
                      getCandidateDetailsWithGmWiseWebinars(item, selected_gm_level.level)
                    "
                  >
                    {{ item.level_count }}
                  </p>
                </td>
                
              </tr>
            </tbody> -->
            <thead v-if="selected_gm_level != 'all'">
              <tr>
                <th>GM</th>
                <th>SPOC</th>
                <th>
                  {{ selected_gm_level.level }}
                </th>
              </tr>
            </thead>
            <thead v-if="selected_gm_level == 'all'">
              <tr>
                <th>GM</th>
                <th>SPOC</th>
                <th v-for="level in spoc_levels_list" :key="level">
                  {{ level }}
                </th>
              </tr>
            </thead>
            <tbody v-if="selected_gm_level == 'all'">
              <tr v-for="item in gm_level_wise_spoc_data.spocs" :key="item.spoc_id">
                <td>{{gm_level_wise_spoc_data.gm_name}}</td>
                <td>
                  {{ item.spoc_name }}
                </td>
                <td v-for="level in item.levels" :key="level.level">
                  <p
                    @click="
                      getCandidateDetailsWithGmWiseWebinars(item, level.level, gm_level_wise_spoc_data.gm_id)
                    "
                  >
                    {{ level.level_count }}
                  </p>
                </td>
                
              </tr>
            </tbody>
            <tbody v-if="selected_gm_level != 'all'">
              <tr v-for="item in gm_level_wise_spoc_data.spocs" :key="item.spoc_id">
                <td>{{gm_level_wise_spoc_data.gm_name}}</td>
                <td>
                  {{ item.spoc_name }}
                </td>
                <td v-for="level in item.levels.filter(info => info.level === selected_gm_level.level)" :key="level.level" >
                  <!-- <template v-if="level.level == selected_gm_level.level"> -->
                    <p
                      @click="
                        getCandidateDetailsWithGmWiseWebinars(item, level.level, gm_level_wise_spoc_data.gm_id)
                      "
                    >
                      {{ level.level_count }}
                    </p>
                  <!-- </template> -->
                </td>
                
              </tr>
            </tbody>
          </table>
        </vs-col>
      </vs-row>
    </div>
    <br />
    <div v-if="candidates_data.length > 0" class="filter-card-wd">
      <new-webinar-dashboard-tabulator
        :candidates_data="candidates_data"
      ></new-webinar-dashboard-tabulator>
    </div>
    <br/>
    <vs-pagination
      v-if="candidates_data.length > 0" 
      :total="tablelinks"
      v-model="currentTablePage"
    ></vs-pagination>
  </div>
</template>
<script>
import NewWebinarDashboardTabulator from "../components/WebinarDashboardComponents/NewWebinarDashboardTabulator.vue";
import "vue-tree-halower/dist/halower-tree.min.css";
import constants from "../../constants.json";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import DateRangePicker from "vue2-daterange-picker";
export default {
  components: {
    Datepicker,
    NewWebinarDashboardTabulator,
    VTree,
    vSelect,
    VSelectTree,
    Treeselect,
  },
  data() {
    return {
      init_selected_team: [],
      disabled: false,
      selected_teams: [],
      treeTeam: [
        {
          id: "All",
          label: "All",
          children: [
            { id: "CM", label: "CM" },
            { id: "SR", label: "SR" },
            // { id: "MAcc", label: "MAcc" },
          ]
        }
      ],
      alert_message: "",
      login_spoc_id: "",
      show_upload_btn: false,
      show_alert_message: false,
      gm_city_classification: "",
      spoc_levels_list: [],
      gm_level_wise_spoc_data: "",
      current_candidate_row: "",
      current_candidate_spoc_level: "",
      currentTablePage: 1,
      tablelinks: 0,
      logged_in_spoc_cities: [],
      selectedTopicData: "",
      initSelectedtopic: null,
      treeDataTopics: [
        // {
        //   id: "All",
        //   label: "All",
        //   children: [
            // {
            //   id: "AUD",
            //   label: "AUD",
            // },
            // {
            //   id: "FAR BEC",
            //   label: "FAR BEC",
            // },
        //   ]
        // }
      ],
      dates: null,
      selected_level: null,
      selectedLevels: null,
      treeDataLevels: [
          { id: "m_level", label: "CM Level" },
          { id: "u_level", label: "USP Level" }
      ],
      treeDataCMLevel: [
                {
                    id: "All",
                    label: "All",
                    children: [
                        {
                            id: "P1",
                            label: "P1",
                            children: [
                                {
                                    id: "M3",
                                    label: "M3",
                                },
                                {
                                    id: "M3++",
                                    label: "M3++",
                                },
                                {
                                    id: "M3+",
                                    label: "M3+",
                                },
                                {
                                    id: "M5",
                                    label: "M5",
                                },
                                {
                                    id: "M6",
                                    label: "M6",
                                },
                                {
                                    id: "M6 AR",
                                    label: "M6 AR",
                                },
                                {
                                    id: "M6 AR-",
                                    label: "M6 AR-",
                                },
                            ],
                        },
                        {
                            id: "P2",
                            label: "P2",
                            children: [
                                {
                                    id: "M4",
                                    label: "M4",
                                },
                                {
                                    id: "M2",
                                    label: "M2",
                                },
                            ],
                        },
                        {
                            id: "P3",
                            label: "P3",
                            children: [
                                {
                                    id: "M4-",
                                    label: "M4-",
                                },
                                {
                                    id: "M1",
                                    label: "M1",
                                },
                            ],
                        },
                        {
                            id: "ENROLLED",
                            label: "ENROLLED",
                            children: [
                            {
                                id: "M7",
                                label: "M7",
                            },
                            {
                                id: "M7-",
                                label: "M7-",
                            },
                            {
                                id: "M7+",
                                label: "M7+",
                            },
                            {
                                id: "M7#",
                                label: "M7#",
                            },
                            {
                                id: "M7-JJ",
                                label: "M7-JJ",
                            },
                            {
                                id: "M7X",
                                label: "M7X",
                            },
                            {
                                id: "M7D1",
                                label: "M7D1",
                            },
                            {
                                id: "M7D2",
                                label: "M7D2",
                            },
                            {
                                id: "M8#1",
                                label: "M8#1"
                            },
                            {
                                id: "M8#1-",
                                label: "M8#1-"
                            },
                            {
                                id: "M8#1+",
                                label: "M8#1+"
                            },
                            {
                                id: "M8#2",
                                label: "M8#2"
                            },
                            {
                                id: "M8",
                                label: "M8"
                            },
                            {
                                id: "M8-",
                                label: "M8-",
                            },
                            {
                                id: "M8+",
                                label: "M8+"
                            },
                            {
                                id: "M8+1",
                                label: "M8+1"
                            },
                            {
                                id: "M8+2",
                                label: "M8+2"
                            },
                            {
                                id: "M8+3",
                                label: "M8+3"
                            },
                            {
                                id: "M8+4",
                                label: "M8+4"
                            },
                            {
                                id: "M8+5",
                                label: "M8+5"
                            },
                            {
                                id: "M8+6",
                                label: "M8+6"
                            },
                            {
                                id: "M9",
                                label: "M9",
                            },
                            {
                                id: "M9+1",
                                label: "M9+1",
                            },
                            {
                                id: "M9+2",
                                label: "M9+2",
                            },
                            {
                                id: "M9+3",
                                label: "M9+3",
                            },
                            {
                                id: "M9-",
                                label: "M9-",
                            },
                            {
                                id: "M10",
                                label: "M10",
                            },
                            {
                                id: "M10#",
                                label: "M10#",
                            },
                            {
                                id: "M11",
                                label: "M11",
                            },
                            {
                                id: "M11#",
                                label: "M11#",
                            },
                            {
                                id: "M12",
                                label: "M12",
                            },
                            {
                                id: "M7-IR",
                                label: "M7-IR",
                            },
                            {
                                id: "M7-INT",
                                label: "M7-INT",
                            }
                          ]
                        },
                        {
                            id: "Dnd",
                            label: "Dnd",
                            children: [
                                {
                                    id: "N/A",
                                    label: "N/A",
                                },
                                {
                                    id: "DND",
                                    label: "DND",
                                },
                            ]
                        }
                    ],
                },
            ],
      macc_levels: [
          {
              id: "All",
              label: "All",
              children: [
                  { id: "blank", label: "No U Level" },
                  { id: "U0--", label: "U0--" },
                  { id: "U0", label: "U0" },
                  { id: "U1", label: "U1" },
                  { id: "U1+", label: "U1+" },
                  { id: "U2", label: "U2" },
                  { id: "U3-", label: "U3-" },
                  { id: "U3", label: "U3" },
                  { id: "U3+", label: "U3+" },
                  { id: "U3++", label: "U3++"},
                  { id: "U4", label: "U4" },
                  { id: "U5", label: "U5" },
                  { id: "U6", label: "U6" },
                  { id: "U7-", label: "U7-" },
                  { id: "U7", label: "U7" },
                  { id: "U7R", label: "U7R" },
                  { id: "U7+", label: "U7+" },
                  { id: "U8", label: "U8" },
                  { id: "U8+", label: "U8+" },
                  { id: "U9-", label: "U9-" },
                  { id: "U9", label: "U9" },
                  { id: "U9+", label: "U9+" },
                  { id: "U10-", label: "U10-" },
                  { id: "U10", label: "U10" },
                  { id: "U11", label: "U11" },
                  { id: "U11+", label: "U11+" },
                  { id: "U12", label: "U12" },
              ]
          }
      ],
      initSelectedCMLevel: [],
      init_selected_iiml_levels: [],
      selected_cm: [],
      selectedMAccLevels: [],
      selectedCourseData: [],
      initSelectedCourse: [],
      treeDataCourses: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "CPA",
              label: "CPA",
            },
            {
              id: "CPA-AA",
              label: "CPA-AA",
            },
            // {
            //   id: "AICPA",
            //   label: "AICPA",
            // },
            // {
            //   id: "CISA",
            //   label: "CISA",
            // },
            // {
            //   id: "EA",
            //   label: "EA",
            // },
            // {
            //   id: "CIA",
            //   label: "CIA",
            // },
            {
              id: "CMA",
              label: "CMA",
            },
            {
              id: "CFA",
              label: "CFA",
            },
            {
              id: "FRM",
              label: "FRM",
            },
            {
              id: "USP",
              label: "USP",
            },
            {
              id: "RPO",
              label: "RPO",
            },
            // {
            //   id: "IIML-FA",
            //   label: "IIML-FA",
            // },
            // {
            //   id: "IIML-FT",
            //   label: "IIML-FT",
            // },
            // {
            //   id: "IIML-SF",
            //   label: "IIML-SF",
            // },
            // {
            //   id: "IIML-DA",
            //   label: "IIML-DA",
            // },
            // {
            //   id: "IIML-HR",
            //   label: "IIML-HR",
            // },
            // {
            //   id: "IITR-BF",
            //   label: "IITR-BF",
            // },
            // {
            //   id: "IITR-DB",
            //   label: "IITR-DB",
            // },
            // {
            //   id: "IITM-AA",
            //   label: "IITM-AA",
            // },
            // { id: "IIMK-CX", label: "IIMK-CX" },
            // { id: "IITM-FS", label: "IITM-FS" },
            // { id: "IITR-CC", label: "IITR-CC" },
            // { id: "IIMK-FT", label: "IIMK-FT" },
            // { id: "IIML-AB", label: "IIML-AB" },
            // { id: "IIML-SH", label: "IIML-SH" },
            // { id: "IITJ-DE", label: "IITJ-DE" },
            // { id: "XLRI-HR", label: "XLRI-HR" },
            // { id: "XLRI-SH", label: "XLRI-SH" },
            // { id: "IIMI-BA", label: "IIMI-BA" },
            // { id: "XLRI-DM", label: "XLRI-DM" },
            // {
            //   id: "IIML-PM",
            //   label: "IIML-PM",
            // },
          ],
        },
      ],
      spoc_ids: [],
      selected_gm_level: "",
      selected_city: [],
      selected_spocs: [],
      initSelectedCity: [],
      treeDataCity: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedGM: [],
      treeDataGM: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedSpoc: [],
      treeDataSpoc: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      candidates_data: [],
      stats_obj: {
        Registered: "registered",
        Attended: "attended",
        Interested: "interested",
        "Not Attended": "not_attended",
        "Pending Net Enquiry Insertion": "pending_net_enquiry_insertion",
        // "Not yet in MWB": "not_in_mwb",
        // Connected: "connected",
        // "Non Connected": "not_connected",
        "Lead Not Created": "lead_not_created",
        "Pre Enrolled": "pre_enrolled",
        "Post Enrolled": "post_enrolled",
        Dialed: "dailed",
        "Not Dialed": "not_dailed",
        // "Upload Status": "upload_status",
      },
      show: false,
      selected_cell: {},
      levels_list: [],
      stat_wise_gm_data: [],
      selected_stat: null,
      webinar_stats_tree_data: [],
      stats_names: [],
      stats: null,
      webinars: [],
      selected_webinar: null,
      remove_webinar: "",
      webinar_stats_list: [
        { name: "Registered", count: 354 },
        { name: "Attended", count: 754 },
        { name: "Interested", count: 2343 },
        { name: "Not Attended", count: 7456 },
        { name: "Pending NE insertion", count: 67 },
        { name: "Lead Not Ctreated", count: 454 },
      ],
      users: [
        {
          city: "Hyd",
          reporting_to_name: 1,
          spoc_name: "sridhar",
          spoc_level: "M7",
          total_tp_enrollments_count: "",
          ne_assigned: "",
          last_week_enrollments_count: "",
          last_seven_enrollments_count: "",
          total_tp_ne_count: "",
        },
      ],
      selected_gm_spocs: [],
      loggedin_team: "",
    };
  },
  mounted() {
    this.login_spoc_id = localStorage.getItem("spoc_id");
    console.log("gm_level_wise_spoc_data",this.gm_level_wise_spoc_data);
    this.getUsersWithGMs();
    this.userWiseTeam();
    this.fetchWebinars();
    this.selected_level = 'm_level';
  },
  watch: {
    init_selected_team(val) {
      console.log("init_selected_team", val);
      this.mergeAllSearch();
    },
    selected_level: function () {
        this.initSelectedCMLevel = [];
        this.init_selected_iiml_levels = [];
        this.mergeAllSearch()
    },
    initSelectedCMLevel: function () {
        this.mergeAllSearch();
    },
    init_selected_iiml_levels: function () {
        this.mergeAllSearch();
    },
    gm_level_wise_spoc_data(val){
      console.log("gm_level_wise_spoc_data",val);
    },
    currentTablePage(){
      console.log("djkfhdksjghkjgh",this.current_candidate_row, this.current_candidate_spoc_level, this.gm_level_wise_spoc_data);
      this.getCandidateDetailsWithGmWiseWebinars(this.current_candidate_row, this.current_candidate_spoc_level, this.gm_level_wise_spoc_data.gm_id);
    },
    initSelectedCourse(){
      this.mergeAllSearch();  
    },
    initSelectedtopic(val){
      console.log("initSelectedtopic dfgfdgr",val);
      if(val == undefined || val == null){
        this.selectedTopicData = "";
      }
      this.mergeAllSearch();   
    },
    initSelectedGM(val) {
      console.log("initSelectedGM", val);
      this.mergeAllSearch();
    },
    initSelectedSpoc() {
      this.mergeAllSearch();
    },
    selected_stat(val) {
      this.stat_wise_gm_data = [];
      this.candidates_data = [];
      this.gm_level_wise_spoc_data = "";
      if (val != null) {
        this.mergeAllSearch();
        // this.webinarStatsGmWiseDetails();
      }
    },
    initSelectedCity(val) {
      this.mergeAllSearch();
      console.log("initSelectedCity", val);
    },
    selected_webinar(val) {
      
      this.show_upload_btn = false;
      this.show_alert_message = false;
      if(val != undefined){
        this.webinar_id = val.webinar_id;
      }
      console.log("selected_webinar",val);
      if(val == undefined){
        this.stats_names = [];
        this.remove_webinar = "";
        this.gm_level_wise_spoc_data = "";
      }
      this.mergeAllSearch();
      // this.getWebinarStats(val);
    },
  },
  methods: {
    uploadWebinarData() {
      if (this.stats.upload_status == 0) {
        this.show_alert_message = true;
        this.alert_message = "Data Upload";
      } else if (this.stats.upload_status == 1) {
        this.show_alert_message = true;
        this.alert_message =
          " Data Being Uploaded Please Check After Some Time";
      } else if (this.stats.upload_status == 2) {
        this.show_alert_message = true;
        this.alert_message = " Data Has Been Uploaded";
      }

      // console.log("this.webinar_id", this.webinar_id)
      let obj = {
        webinar_id: this.webinar_id,
      };
      console.log(obj);
      let url = `${constants.MILES_WEBINARS}uploadWebinarsData`;
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    checkGM(item, level){
      this.currentTablePage = 1;
      this.candidates_data = [];
      if(item.gm_id != 'no_candidate' && item.gm_id != 'no_gm' ){
        this.getCellData(item, level) 
      }
      if(item.gm_id == 'no_candidate' || item.gm_id == 'no_gm' ){
        this.getCandidateDetailsWithGmWiseWebinars(item, level, item.gm_id) 
      }
    },
    refreshData(){
      this.candidates_data = [];
      this.selected_cell = [];
      this.stat_wise_gm_data = [];
      this.remove_webinar = "";
      this.selected_stat = null;
      this. selected_gm_spocs = [];
      this.selected_spocs = [];
      this.selectedCourseData = [];
      this.selectedTopicData = "";
      this.selected_webinar = null;
      this.selected_stat = null;
      this.initSelectedCity = [];
      this.initSelectedGM = [];
      this.initSelectedSpoc = [];
      this.initSelectedCourse = [];
      this.initSelectedtopic = null;
      this.selected_level = 'm_level';
      this.stats_names = [];
      this.init_selected_team = [];
      this.clearDateFilter();
      this.userWiseTeam();
      this.getUsersWithGMs();
    },
    clearDateFilter() {
      this.lastCallDateateRange = {
        startDate: null,
        endDate: null,
      };
      this.dates = null;
    },
    customFormatter(date) {
      return moment(date).format("MMM-YYYY");
    },
    getWebinarStats(val){
      this.gm_level_wise_spoc_data = "";
      var year = "";
      var month = "";
      if (this.dates != null) {
        year = moment(this.dates).format("YYYY");
        month = moment(this.dates).format("MM");
      }
      let encoded_levels = [];
      if (this.selected_cm.length != 0 ) {
        for (let i = 0; i < this.selected_cm.length; i++) {
          const element = this.selected_cm[i];
          let encoded_level = encodeURIComponent(element);
          encoded_levels.push(encoded_level);
        }
      } else if(this.selectedMAccLevels.length != 0 ) {
        for (let i = 0; i < this.selectedMAccLevels.length; i++) {
        const element = this.selectedMAccLevels[i];
        let encoded_level = encodeURIComponent(element);
        encoded_levels.push(encoded_level);
      }
      }
      this.$vs.loading();
      this.mergeAllSearch();
      this.stats = {};
      let url = `https://mileswebinars.2x2.ninja/api/getWebinarStatsOverallCount/${val.id}?team=${this.selected_teams.toString()}&spoc_ids=${this.spoc_ids}&courses=${this.selectedCourseData.join()}&levels=${encoded_levels}&topic=${this.selectedTopicData}&year=${year}&month=${month}`
      if(this.selected_level != null){
        url = `https://mileswebinars.2x2.ninja/api/getWebinarStatsOverallCount/${val.id}?team=${this.selected_teams.toString()}&spoc_ids=${this.spoc_ids}&courses=${this.selectedCourseData.join()}&level_type=${this.selected_level.toString()}&levels=${encoded_levels}&topic=${this.selectedTopicData}&year=${year}&month=${month}`
      }
      axios
        .get(url,
        {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        }
        )
        .then((response) => {
        console.log("Webinar Stats", response.data);
        this.stats = {
          Registered: response.data.registered,
          Attended: response.data.attended,
          Interested: response.data.interested,
          "Not Attended": response.data.not_attended,
          "Pending Net Enquiry Insertion":
            response.data.pending_net_enquiry_insertion,
          // "Not yet in MWB": response.data.not_in_mwb,
          // Connected: response.data.connected,
          // "Non Connected": response.data.not_connected,
          "Lead Not Created": response.data.lead_not_created,
          "Pre Enrolled": response.data.pre_enrolled,
          "Post Enrolled": response.data.post_enrolled,
          Dialed: response.data.dailed,
          "Not Dialed": response.data.not_dailed,
          upload_status: response.data.upload_status,
        };
        if (response.data.upload_status == 0) {
          this.show_upload_btn = true;
        }
        console.log("Webinar Stats", this.stats);

        this.candidates_data = [];
        this.selected_cell = [];
        this.stat_wise_gm_data = [];
        this.stats_names = [];
        this.webinar_stats_tree_data = [];
        this.stats_names = Object.keys(this.stats);
        this.stats_names.forEach((element) => {
          let obj = {
            id: element,
            label: element,
          };
          this.webinar_stats_tree_data.push(obj);
        });
        // webinar_stats_tree_data
        this.$vs.loading.close();
      })
      .catch((error) => {
        this.$vs.loading.close();
        this.handleError(error);
      });
    },
    getSpocIds(){
      let city = [];
      let cc = [];
      let uniqe_city =[];
      let uniqe_gm = [];
      let uniqe_spoc =[];
      if(this.initSelectedCity.length != 0){
        this.initSelectedCity.forEach(item => {
          if(item.hasOwnProperty('children')){
            city.push(item.id);
            item.children.forEach(CC => {
              cc.push(CC.label)
            })
          }
          else if(!item.hasOwnProperty('children')){
            city.push(item.id);
            cc.push(item.label)
          }
        });
        
        uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
        uniqe_city = [...new Set(uniqe_city.map((item) => item))];
      }
      if(this.initSelectedGM.length != 0){
        this.initSelectedGM.forEach(item => {
          if(item.hasOwnProperty('children')){
            city.push(item.id);
            item.children.forEach(data => {
              uniqe_gm.push(data.label)
            })
          }
          else if(!item.hasOwnProperty('children')){
            city.push(item.id);
            uniqe_gm.push(item.label)
          }
        });
        
        uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
        uniqe_city = [...new Set(uniqe_city.map((item) => item))];
      }
      if(this.initSelectedSpoc.length != 0){
        this.initSelectedSpoc.forEach(item => {
          if(item.hasOwnProperty('children')){
            city.push(item.id);
            item.children.forEach(data => {
              uniqe_spoc.push(data.label)
            })
          }
          else if(!item.hasOwnProperty('children')){
            city.push(item.id);
            uniqe_spoc.push(item.label)
          }
        });
        
        uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
        uniqe_city = [...new Set(uniqe_city.map((item) => item))];
      }
      let spoc_ids = [];
      this.user_with_gms.forEach(raw => {
        if(this.initSelectedCity.length != 0){
          console.log("condition 1")
            uniqe_city.forEach(city => {
            cc.forEach(cc => {
              if(raw.city == city && raw.city_classification == cc){
                spoc_ids.push(raw.id);
              }
            })
          })
        } if(this.initSelectedGM.length != 0){
          uniqe_city.forEach(city => {
              uniqe_gm.forEach(gm => {
                 if(raw.city == city && raw.gm == gm){
                    spoc_ids.push(raw.id);
                  }
              })
          })
        }
        // uniqe_city.length != 0 && uniqe_gm.length != 0 &&
         if( this.initSelectedSpoc.length != 0){
          uniqe_city.forEach(city => {
                 uniqe_spoc.forEach(spoc => {
                  if(raw.city == city  && raw.first_name+" "+raw.last_name == spoc){
                    spoc_ids.push(raw.id);
                  }
            })
          })
        }
        
      });
      this.spoc_ids = [];
      this.spoc_ids = [...new Set(spoc_ids.map((data) => data))];
      console.log("spoc Ids",spoc_ids.join())
    },
    searchWebinar() {
      this.getSpocIds();
      if (this.selected_webinar == null || this.selected_webinar == undefined) {
          this.$vs.notify({
              title: "Please select webinar",
              color: "danger",
          });
      } else {
        this.getWebinarStats(this.selected_webinar);
      }
    },
    removechip(chip, name) {
      if (name == 'level') {
        this.selected_level = null,
        this.selectedLevels = null
      }
      if (name == "webinar_stat") {
        this.selected_stat = null;
        // this.remove_webinar = "";
      }
      if (name == "webinar") {
        this.selected_webinar = null;
        this.remove_webinar = "";
        console.log("fdgfdhfh", this.selected_webinar);
      }
      if (name == "spoc") {
        let index = this.initSelectedSpoc.findIndex((x) => x.label === chip);
        this.initSelectedSpoc.splice(index, 1);
        this.selected_spocs.splice(this.selected_spocs.indexOf(chip), 1);
      }
      if (name == "gm") {
        let index = this.initSelectedGM.findIndex((x) => x.label === chip);
        this.initSelectedGM.splice(index, 1);
        this.selected_gm_spocs.splice(this.selected_gm_spocs.indexOf(chip), 1);
      }
      if (name == "team") {
                this.selected_teams = [];
                if (this.init_selected_team.indexOf(chip) === -1) {
                    this.init_selected_team = [];
                    this.treeTeam[0].children.forEach((child) => {
                        if (child.label !== chip) {
                            this.init_selected_team.push(child.label);
                            this.selected_teams.push(child.label);
                        }
                    });
                } else {
                    this.init_selected_team.splice(this.init_selected_team.indexOf(chip), 1);
                    this.selected_teams.splice(this.selected_teams.indexOf(chip), 1);
                }
            }
      if (name == "city") {
        let index = this.initSelectedCity.findIndex((x) => x.label === chip);
        this.initSelectedCity.splice(index, 1);
        this.selected_city.splice(this.selected_city.indexOf(chip), 1);
      }
      if (name == "course") {
        if (this.initSelectedCourse.indexOf(chip) === -1) {
          this.initSelectedCourse = [];
          this.treeDataCourses[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedCourse.push(child.label);
              this.selectedCourseData.push(child.label);
            }
          });
        } else {
          this.initSelectedCourse.splice(this.initSelectedCourse.indexOf(chip), 1);
          this.selectedCourseData.splice(
            this.selectedCourseData.indexOf(chip),
            1
          );
        }
      }

      if (name == "topic") {
        this.selectedTopicData = "";
        this.initSelectedtopic  = null;
        // let index = this.initSelectedtopic.findIndex((x) => x.label === chip);
        // this.initSelectedtopic.splice(index, 1);
        // this.selected_topic.splice(this.selected_topic.indexOf(chip), 1);
      }

      if (name == "cm_level") {
        if (this.initSelectedCMLevel.indexOf("All") == 0) {
          this.initSelectedCMLevel = [];
          this.treeDataCMLevel[0].children.forEach((child) => {
            child.children.forEach((chi) => {
              if (chi.label !== chip) {
                this.initSelectedCMLevel.push(chi.label);
                this.selected_cm.push(chi.label);
              }
            });
          });
        } else if (this.initSelectedCMLevel.indexOf(chip) !== -1) {
          this.initSelectedCMLevel.splice(this.initSelectedCMLevel.indexOf(chip), 1);
          this.selected_cm.splice(this.initSelectedCMLevel.indexOf(chip), 1);
        } else {
          let childProperty = "";
          this.treeDataCMLevel[0].children.forEach((child) => {
            if ("children" in child) {
              child.children.forEach((chi) => {
                if (chi.label === chip) {
                  childProperty = child.label;
                }
              });
            }
          });
          this.initSelectedCMLevel.splice(
            this.initSelectedCMLevel.indexOf(childProperty),
            1
          );
          this.selected_cm.splice(
            this.initSelectedCMLevel.indexOf(childProperty),
            1);
          this.treeDataCMLevel[0].children.forEach((child) => {
            if (child.label === childProperty) {
              if ("children" in child) {
                child.children.forEach((chi) => {
                  if (chi.label !== chip) {
                    this.initSelectedCMLevel.push(chi.label);
                    this.selected_cm.push(chi.label);
                  }
                });
              }
            }
          });
        }
      } else if (name == "Mlevel") {
        this.selectedMAccLevels = [];
        if (this.init_selected_iiml_levels.indexOf(chip) === -1) {
            this.init_selected_iiml_levels = [];
            this.macc_levels[0].children.forEach((child) => {
                if (child.label !== chip) {
                    this.init_selected_iiml_levels.push(child.label);
                    this.selectedMAccLevels.push(child.label);
                }
            });
        } else {
            this.init_selected_iiml_levels.splice(this.init_selected_iiml_levels.indexOf(chip), 1);
            this.selectedMAccLevels.splice(
                this.selectedMAccLevels.indexOf(chip),
                1
            );
        }
    }
    },
    mergeAllSearch() {
      this.selected_teams = [];
      this.init_selected_team.forEach((sort) => {
        console.log("init_selected_teamwewe", sort);
        if (sort === "All") {
          this.treeTeam[0].children.forEach((child) => {
            this.selected_teams.push(child.label);
          });
        } else {
          this.selected_teams.push(sort);
        }
      });
      this.selectedLevels = null;
      this.selectedLevels = this.selected_level
      if(this.selected_webinar != null && this.selected_webinar != undefined){
        this.remove_webinar = "";
        this.remove_webinar = this.selected_webinar.label;
      }
      // if(this.selected_webinar == null && this.selected_webinar == undefined){
      //   this.remove_webinar = "";
      // }
      this.selected_spocs = [];
      this.initSelectedSpoc.forEach((sort) => {
        if (sort.label === "All") {
          this.SpoctreeData[0].children.forEach((child) => {
            if (!this.selected_spocs.includes(child.label)) {
              this.selected_spocs.push(child.label);
            }
          });
        } else {
          if (!this.selected_spocs.includes(sort.label)) {
            this.selected_spocs.push(sort.label);
          }
        }
      });
      this.selected_gm_spocs = [];
      this.initSelectedGM.forEach((sort) => {
        if (sort.label === "All") {
          this.treeDataGM[0].children.forEach((child) => {
            if (!this.selected_gm_spocs.includes(child.label)) {
              this.selected_gm_spocs.push(child.label);
            }
          });
        } else {
          if (!this.selected_gm_spocs.includes(sort.label)) {
            this.selected_gm_spocs.push(sort.label);
          }
        }
      });
      this.selected_city = [];
      this.initSelectedCity.forEach((sort) => {
        if (sort.label === "All") {
          this.treeDataCity[0].children.forEach((child) => {
            if (!this.selected_city.includes(child.label)) {
              this.selected_city.push(child.label);
            }
          });
        } else {
          if (!this.selected_city.includes(sort.label)) {
            this.selected_city.push(sort.label);
          }
        }
      });
      this.selectedCourseData = [];
      this.initSelectedCourse.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            // this.searchCriteria.push(child.label);
            this.selectedCourseData.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.selectedCourseData.push(sort);
        }
      });
      this.selectedCourseData = [];
      this.initSelectedCourse.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            // this.searchCriteria.push(child.label);
            this.selectedCourseData.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.selectedCourseData.push(sort);
        }
      });

      // this.selectedTopicData = [];
      // this.initSelectedtopic.forEach((sort) => {
      //   if (sort === "All") {
      //     this.treeDataTopics[0].children.forEach((child) => {
      //       // this.searchCriteria.push(child.label);
      //       this.selectedTopicData.push(child.label);
      //     });
      //   } else {
      //     // this.searchCriteria.push(sort);
      //     this.selectedTopicData.push(sort);
      //   }
      // });

      if(this.initSelectedtopic != null && this.initSelectedtopic != undefined){
        this.selectedTopicData = "";
        this.selectedTopicData = this.initSelectedtopic.label;
      }

      this.selected_cm = [];
      this.initSelectedCMLevel.forEach((sort) => {
          if (sort === "All") {
              this.treeDataCMLevel[0].children[0].children.forEach((child) => {
                  this.selected_cm.push(child.label);
              });
              this.treeDataCMLevel[0].children[1].children.forEach((child) => {
                  this.selected_cm.push(child.label);
              });
              this.treeDataCMLevel[0].children[2].children.forEach((child) => {
                  this.selected_cm.push(child.label);
              });
              this.treeDataCMLevel[0].children[3].children.forEach((child) => {
                  this.selected_cm.push(child.label);
              });
              this.treeDataCMLevel[0].children[4].children.forEach((child) => {
                  this.selected_cm.push(child.label);
              });
          } else if (sort === "P1") {
              this.treeDataCMLevel[0].children[0].children.forEach((child) => {
                  this.selected_cm.push(child.label);
              });
          } else if (sort === "P2") {
              this.treeDataCMLevel[0].children[1].children.forEach((child) => {
                  this.selected_cm.push(child.label);
              });
          } else if (sort === "P3") {
              this.treeDataCMLevel[0].children[2].children.forEach((child) => {
                  this.selected_cm.push(child.label);
              });
          } else if (sort === "ENROLLED") {
              this.treeDataCMLevel[0].children[3].children.forEach((child) => {
                  this.selected_cm.push(child.label);
              });
          }
          else if (sort === "Dnd") {
              this.treeDataCMLevel[0].children[4].children.forEach((child) => {
                  this.selected_cm.push(child.label);
              });
          } else {
              this.selected_cm.push(sort);
          }
      });
      this.selectedMAccLevels = [];
      this.init_selected_iiml_levels.forEach((sort) => {
          if (sort === "All") {
              this.macc_levels[0].children.forEach((child) => {
                  this.selectedMAccLevels.push(child.label);
              });
          } else {
              this.selectedMAccLevels.push(sort);
          }
      });

    },
    getCandidateDetailsWithGmWiseWebinars(row, spoc_level, gm_id) {
       this.$vs.loading();
      console.log("getCandidateDetailsWithGmWiseWebinars row, spoc_level",row, spoc_level);
      if(row.spoc_id != this.current_candidate_row.spoc_id)
      {
        this.currentTablePage =1;


      }
      this.current_candidate_row = "";
      this.current_candidate_spoc_level = "";
      this.current_candidate_row = row;
      this.current_candidate_spoc_level = spoc_level;
      var year = "";
      var month = "";
      if (this.dates != null) {
        year = moment(this.dates).format("YYYY");
        month = moment(this.dates).format("MM");
      }
      this.candidates_data = [];
      
      // var level = spoc_level;
      // if(spoc_level == 'all'){
      //   level = this.spoc_levels_list;
      // }
      var level = "";
      level = encodeURIComponent(spoc_level);
      if(spoc_level == 'all'){
        level = [];
        for (let i = 0; i < this.spoc_levels_list.length; i++) {
          const element = this.spoc_levels_list[i];
          let encoded_level = encodeURIComponent(element);
          level.push(encoded_level);
        }
        level = level.join();
      }

      let encoded_levels = [];
      if (this.selected_cm.length != 0 ) {
        for (let i = 0; i < this.selected_cm.length; i++) {
        const element = this.selected_cm[i];
        let encoded_level = encodeURIComponent(element);
        encoded_levels.push(encoded_level);
      }
      // encoded_levels = this.selected_cm
      } else if(this.selectedMAccLevels.length != 0)  {
        for (let i = 0; i < this.selectedMAccLevels.length; i++) {
        const element = this.selectedMAccLevels[i];
        let encoded_level = encodeURIComponent(element);
        encoded_levels.push(encoded_level);
      }
      // encoded_levels = this.selectedMAccLevels
      }

      let url = `https://mileswebinars.2x2.ninja/api/candidateDetailsWithOverallGmWiseWebinars/${this.selected_webinar.id}?team=${this.selected_teams.toString()}&category=${this.stats_obj[this.selected_stat]}&gm_id=${gm_id}&spoc_id=${row.spoc_id
      }&levels=${encoded_levels}&level=${level}&spoc_ids=${this.spoc_ids}&courses=${this.selectedCourseData.join()}&topic=${this.selectedTopicData}&year=${year}&month=${month}&page=${this.currentTablePage}`;
      if(this.selected_level != null){
        url = `https://mileswebinars.2x2.ninja/api/candidateDetailsWithOverallGmWiseWebinars/${this.selected_webinar.id}?team=${this.selected_teams.toString()}&category=${this.stats_obj[this.selected_stat]}&gm_id=${gm_id}&spoc_id=${row.spoc_id
      }&level_type=${this.selected_level.toString()}&levels=${encoded_levels}&level=${level}&spoc_ids=${this.spoc_ids}&courses=${this.selectedCourseData.join()}&topic=${this.selectedTopicData}&year=${year}&month=${month}&page=${this.currentTablePage}`;
      }
      axios.get(url,
      {
        headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      }
      ).then((response) => {
        
        this.candidates_data = response.data.data;
        if(this.candidates_data.length != 0){
          for (let i = 0; i < this.candidates_data.length; i++) {
            const element = this.candidates_data[i];
            console.log("dfhiuerthreut date",element.last_call, element.next_call, element.mhp_date);
            element.lead_created_date = this.customDateFormatter(element.lead_created_date);
            if(element.last_call != null){
              element.last_call = this.unixToCustomDateFormatter(element.last_call)
            }
            if(element.next_call != null){
            element.next_call = this.unixToCustomDateFormatter(element.next_call)
            }
            if(element.mhp_date != null){
            element.mhp_date = this.unixToCustomDateFormatter(element.mhp_date)
            }
            if("fam_lam" in element){
              element.fam_lam.first_enquiry_date = this.customDateFormatter(element.fam_lam.first_enquiry_date)
              element.fam_lam.last_enquiry_date = this.customDateFormatter(element.fam_lam.last_enquiry_date)
            }
            Object.assign(element, {
              info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg",
            });
          }
        }
        this.tablelinks = response.data.last_page;
        this.$vs.loading.close();
      })
      .catch((error) => {
        this.handleError(error);
        this.$vs.loading.close();
      });
    },
    customDateFormatter(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    unixToCustomDateFormatter(date){
      return moment.unix(date).format("DD-MMM-YYYY");
    },
    getCellData(spoc, level) {
      this.$vs.loading();
      this.currentTablePage = 1;
      this.candidates_data = [];
      this.gm_level_wise_spoc_data = "";
      this.selected_gm_level = "";
      this.selected_gm_level = level;
      console.log("djkfhdsuhuh 1",spoc, level);
      var year = "";
      var month = "";
      if (this.dates != null) {
        year = moment(this.dates).format("YYYY");
        month = moment(this.dates).format("MM");
      }
      var s_level = "";
      if(level != 'all'){
        s_level = encodeURIComponent(level.level);
      }
      if(level == 'all'){
        s_level = [];
        for (let i = 0; i < spoc.gm_levels.length; i++) {
          const element = spoc.gm_levels[i];
          let encoded_level = encodeURIComponent(element.level);
          s_level.push(encoded_level);
        }
        s_level = s_level.join();
      }
     console.log("djkfhdsuhuh 2",spoc);
     this.gm_city_classification = spoc.gm_city;
     let url = `https://mileswebinars.2x2.ninja/api/webinarSpocLevelCount/${this.selected_webinar.id}?team=${this.selected_teams.toString()}&category=${this.stats_obj[this.selected_stat]}&gm_id=${spoc.gm_id}&city_classification=${spoc.gm_city}&levels=${s_level}&year=${year}&month=${month}&courses=${this.selectedCourseData.join()}&spoc_ids=${this.spoc_ids}`
      if(this.selected_level != null){
        url = `https://mileswebinars.2x2.ninja/api/webinarSpocLevelCount/${this.selected_webinar.id}?team=${this.selected_teams.toString()}&category=${this.stats_obj[this.selected_stat]}&gm_id=${spoc.gm_id}&city_classification=${spoc.gm_city}&level_type=${this.selected_level.toString()}&levels=${s_level}&year=${year}&month=${month}&courses=${this.selectedCourseData.join()}&spoc_ids=${this.spoc_ids}`
      }
      axios
        .get(
          url,
          {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          }
        )
        .then((response) => {
          this.spoc_levels_list = [];
          console.log("webinarSpocLevelCount", response);
          if (response.data.length > 0 && response.data != 'Something Went Wrong') {
            this.gm_level_wise_spoc_data = response.data[0];
            console.log("webinarSpocLevelCount",  this.gm_level_wise_spoc_data);
            //Listing SPOC Levels
            this.gm_level_wise_spoc_data.spocs[0].levels.forEach((element) => {
              this.spoc_levels_list.push(element.level);
            });
            console.log("this.gm_level_wise_spoc_data",this.gm_level_wise_spoc_data);
          //   this.stat_wise_gm_data = response.data;
          //   this.stat_wise_gm_data[0].gm_levels;
          //   this.stat_wise_gm_data[0].gm_levels.forEach((element) => {
          //     this.levels_list.push(element.level);
          //   });
          //   //To get distinct levels
          //   this.levels_list = [
          //     ...new Set(this.levels_list.map((item) => item)),
          //   ];
            
          // }
          // if(response.data.gm_name == "No Gm"){
          //   this.stat_wise_gm_data = response.data;
          }
          // console.log("stat_wise_gm_data sfrdr", this.stat_wise_gm_data);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });



      // console.log("getCellData dgfgfg", spoc, level);
      // this.candidates_data = [];
      // if(spoc.gm_name != 'No GM'){
      //   this.show = false;
      //   this.selected_cell = {};
      //   this.selected_cell = spoc;
      //   this.selected_gm_level = level;
      //   if(level != 'all'){
      //     for (let i = 0; i < spoc.spocs.length; i++) {
      //       const element = spoc.spocs[i];
      //       for (let j = 0; j < element.levels.length; j++) {
      //         const level_element =  element.levels[j];
      //         console.log("getCellData dgfgfg 1",level_element.level, level);
      //         if(level_element.level == level.level){
      //           console.log("getCellData dgfgfg satisfied",level_element.level, level);
      //           Object.assign(element, {spoc_level: level.level, level_count: level_element.level_count})
      //         }
      //       }
      //     }
      //   }
      //   console.log("getCellData dgfgfg 2", spoc, level, this.selected_cell);
      //   this.show = true;
      // }
      // if(spoc.gm_name == 'No GM'){
      //   this.getCandidateDetailsWithGmWiseWebinars(spoc, level); 
      // }

    },
    getStatData(stat) {
      this.currentTablePage = 1;
      console.log("getStatData stat", stat);
      this.candidates_data = [];
      this.selected_stat = stat;
      this.webinarStatsGmWiseDetails();
    },
    webinarStatsGmWiseDetails() {
      this.candidates_data = [];
      this.$vs.loading();
      var year = "";
      var month = "";
      if (this.dates != null) {
        year = moment(this.dates).format("YYYY");
        month = moment(this.dates).format("MM");
      }

      let encoded_levels = [];
      if (this.selected_cm.length != 0 ) {
        for (let i = 0; i < this.selected_cm.length; i++) {
        const element = this.selected_cm[i];
        let encoded_level = encodeURIComponent(element);
        encoded_levels.push(encoded_level);
        }
        // encoded_levels = this.selected_cm
      } else if(this.selectedMAccLevels.length != 0 ) {
        for (let i = 0; i < this.selectedMAccLevels.length; i++) {
         const element = this.selectedMAccLevels[i];
         let encoded_level = encodeURIComponent(element);
        encoded_levels.push(encoded_level);
       }
      // encoded_levels = this.selectedMAccLevels
      }
      this.stat_wise_gm_data = [];
      this.selected_cell = [];
      this.levels_list = [];
      let changed_stat = this.stats_obj[this.selected_stat];

      axios
        .get(
          `https://mileswebinars.2x2.ninja/api/webinarGmLevelOverallCount/${this.selected_webinar.id}?team=${this.selected_teams.toString()}&category=${changed_stat}&spoc_ids=${this.spoc_ids}&courses=${this.selectedCourseData.join()}&level_type=${this.selected_level.toString()}&levels=${encoded_levels}&topic=${this.selectedTopicData}&year=${year}&month=${month}`,
          {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          }
        )
        .then((response) => {
          this.$vs.loading.close();
          console.log("webinarStatsGmWiseDetails fhfdht", response.data);
          if (response.data.length > 0 && response.data != 'Something Went Wrong') {
            this.stat_wise_gm_data = response.data;
            if('gm_levels' in this.stat_wise_gm_data[0]){
              this.stat_wise_gm_data[0].gm_levels;
              this.stat_wise_gm_data[0].gm_levels.forEach((element) => {
                this.levels_list.push(element.level);
              });
            }
            //To get distinct levels
            this.levels_list = [
              ...new Set(this.levels_list.map((item) => item)),
            ];
            
          }
          if(response.data.gm_name == "No Gm"){
            this.stat_wise_gm_data = response.data;
          }
          console.log("stat_wise_gm_data sfrdr", this.stat_wise_gm_data);
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    userWiseTeam() {
      this.init_selected_team = [];
      this.loggedin_team = localStorage.getItem('team');
      if(this.loggedin_team == 'Admin'){
          this.disabled = false;
      } else {
          this.disabled = true;
      }
      if (this.loggedin_team === 'Admin') {
          this.init_selected_team.push('CM');
      }
      if (this.loggedin_team === 'CM') {
          this.init_selected_team.push('CM');
      } else if (this.loggedin_team === 'SR') {
          this.init_selected_team.push('SR');
      } else if (this.loggedin_team === 'ExEd') {
          this.init_selected_team.push('ExEd');
      } else if (this.loggedin_team === 'GM' && localStorage.getItem("sub_team") == 'CM') {
          this.init_selected_team.push('CM');
      }
      else if (this.loggedin_team === 'GM' && localStorage.getItem("sub_team") != 'CM') {
          this.init_selected_team.push('SR');
      }
    },
    getUsersWithGMs() {
      let url = `${constants.SERVER_API}getUsersWithGMs`;
      axios
          .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
          let logged_in_user = localStorage.getItem('spoc_id')
          let team = localStorage.getItem('team')
          if (team == 'CM' || team == 'SR' || team == 'ExEd') {
              response.data.forEach(ele => {
              if (logged_in_user == ele.id) {
                  this.user_with_gms = []
                  this.user_with_gms.push(ele);
                  this.getDropdownData();
              }
              });
          } else if (localStorage.getItem('team') == 'GM') {
              this.getGmReportingSpocs(response.data)
          } else {
              this.sr_users = [];
              this.cm_users = [];
              this.all_users = [];
              this.user_with_gms = [];
              for (let i = 0; i < response.data.length; i++) {
              const element = response.data[i];
                  if(element.team == 'CM'){
                  this.cm_users.push(element);
                  }
                  if(element.team == 'SR'){
                  this.sr_users.push(element);
                  } 
                  this.all_users.push(element);
                  
              }
              this.user_with_gms = [];
              if(this.init_selected_team.includes('CM')){
              this.user_with_gms = this.cm_users;
              this.getDropdownData();
              }
              if(this.init_selected_team.includes('SR')){
              this.user_with_gms = this.sr_users;
              this.getDropdownData();
              }
              if(!this.init_selected_team.includes('SR') && !this.init_selected_team.includes('CM')){
              this.user_with_gms = this.all_users;
              this.getDropdownData();
              }
          }
          })
          .catch((error) => {
          this.handleError(error);
          }).finally(()=>{
          this.getSpocIds();
          })
    },
    getGmReportingSpocs(res) {
      let gm_id = localStorage.getItem("spoc_id");
      let ids = [];
      let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
      axios
          .get(url, {
              headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
              for (let i = 0; i < response.data.spocs.length; i++) {
                  const element = response.data.spocs[i];
                  ids.push(element.id);
              }
              this.gm_ids = ids
              this.user_with_gms = []
              this.gm_ids.forEach(gm => {
                  res.forEach(ele => {
                      if (ele.id == gm) {
                          this.user_with_gms.push(ele);
                      }
                  });
              });
              this.getDropdownData();
          })
          .catch((error) => {
              this.handleError(error);
          });
    },
    getDropdownData() {
    this.initSelectedCity = [];
    this.initSelectedGM = [];
    this.initSelectedSpoc = [];
    this.treeDataCity = [
        {
        id: "All",
        label: "All",
        children: [],
        },
    ]
    let uniqe_city = [...new Set(this.user_with_gms.map((item) => item.city))].sort();
    let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
    uniqe_city.forEach(element => {
        let obj = {
        id: element,
        label: element,
        children: []
        }
        if(city_options.includes(element)){
          this.treeDataCity[0].children.push(obj);
        }
    })
    this.user_with_gms.forEach((element) => {
        this.treeDataCity[0].children.forEach((city) => {
        if (element.city === city.label) {
            let cc_set = new Set(city.children.map((item) => item.label));
            // if (!cc_set.has(element.city_classification) && element.city_classification != "None") {
            // let cc_obj = {
            //     id: element.city + "_" + element.city_classification,
            //     label: element.city_classification
            // }
            // city.children.push(cc_obj);
            // let team = localStorage.getItem('team')
            // if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
            //     this.initSelectedCity.push(cc_obj)
            // }
            // this.default_selected_city = this.initSelectedCity;
            // }
            if (!cc_set.has(element.city_classification) && element.city_classification != "None") {
                        let city_classifaication = element.city_classification
                        let team = localStorage.getItem('team');
                        if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                            let cc_obj = {
                            id: element.city + "_" + element.city_classification,
                            label: element.city_classification
                            }
                            city.children.push(cc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(cc_obj)
                            }
                            this.default_selected_city = this.initSelectedCity;
                        }else{
                        if(city_classifaication.substring(0,1) != 'X'){
                            let cc_obj = {
                                id: element.city + "_" + element.city_classification,
                                label: element.city_classification
                            }
                            city.children.push(cc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(cc_obj)
                            }
                            this.default_selected_city = this.initSelectedCity;
                        }
                        }
                 }
            
            

        }
        })
    });
    this.treeDataGM = [
        {
        id: "All",
        label: "All",
        children: [],
        },
    ],
    uniqe_city.forEach(element => {
        let obj = {
        id: element,
        label: element,
        children: []
        }
        this.treeDataGM[0].children.push(obj);
    })
    this.user_with_gms.forEach((element) => {
        this.treeDataGM[0].children.forEach((city) => {
        if (element.city === city.label) {
            if (element.gm !== "No GM") {
            let gm_set = new Set(city.children.map((item) => item.label));
            // if (!gm_set.has(element.gm)) {
            //     let gm_obj = {
            //     id: element.city + "_" + element.gm,
            //     label: element.gm
            //     }
            //     city.children.push(gm_obj);
            //     let team = localStorage.getItem('team')
            //     if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
            //     this.initSelectedGM.push(gm_obj)
            //     }
            //     this.default_selected_gm = this.initSelectedGM
            // }
            if (!gm_set.has(element.gm)) {
                        let gm_data = element.gm
                        let team = localStorage.getItem('team');
                        if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                            let gm_obj = {
                            id: element.city + "_" + element.gm,
                            label: element.gm
                            }
                            city.children.push(gm_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(gm_obj)
                            }
                            this.default_selected_gm = this.initSelectedGM;
                        }else{
                        if(gm_data.substring(0,1) != 'X'){
                            let gm_obj = {
                                id:element.city + "_" + element.gm,
                                label: element.gm
                            }
                            city.children.push(gm_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(gm_obj)
                            }
                            this.default_selected_gm = this.initSelectedGM;
                        }
                        }
                 }
            }
        }
        })
    });
    this.treeDataSpoc = [
        {
        id: "All",
        label: "All",
        children: [],
        },
    ],
    uniqe_city.forEach(element => {
        let obj = {
        id: element,
        label: element,
        children: []
        }
        this.treeDataSpoc[0].children.push(obj);
    })
    this.user_with_gms.forEach((element) => {
        this.treeDataSpoc[0].children.forEach((city) => {
        if (element.city === city.label) {
            let gm_set = new Set(city.children.map((item) => item.label));
            if (!gm_set.has(element.last_name)) {
                        let name = element.first_name
                        let team = localStorage.getItem('team');
                        if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                            let spoc_obj = {
                            id: element.city + "_" + element.first_name + " " + element.last_name,
                            label: element.first_name + " " + element.last_name
                            }
                            city.children.push(spoc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(spoc_obj.id)
                            }
                this.default_selected_spoc = this.initSelectedSpoc

                        }else{
                        if(name.substring(0,1) != 'X'){
                            let spoc_obj = {
                                id: element.city + "_" + element.first_name + " " + element.last_name,
                                label: element.first_name + " " + element.last_name
                            }
                            city.children.push(spoc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(spoc_obj.id)
                            }
                this.default_selected_spoc = this.initSelectedSpoc

                        }
                        }
                    }
        }
        })
    });
    },
    showTreeBox() {
      return this.treeDataCourses;
    },
 
    fetchWebinars() {
      this.treeDataTopics = []
      axios
        .get("https://mileswebinars.2x2.ninja/api/pastWebinars",
        {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        }
        )
        .then((response) => {
          console.log("pastWebinar", response.data);
          const webinars = [];
          response.data.forEach((w) => {
            let webinar = w;
            webinar.title = `${moment
              .unix(webinar.start_time_unix)
              .format("DD-MMM-YYYY")} : ${w.topic}`;
            webinars.push(webinar);
            let topic_obj = {
              id: webinar.id,
              label: webinar.topic
            }
            this.treeDataTopics.push(topic_obj);
          });
          console.log("jfhgjfhgui", webinars);
          this.webinars = [];
          webinars.forEach((element) => {
            console.log("webinar dashboard", element);
            let webinar_obj = {
              id: element.id,
              label: element.title,
              webinar_id: element.webinar_id
            };
            this.webinars.push(webinar_obj);
          });
          // this.webinars = webinars;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";
.alert_message {
  background: #31a231b5 !important;
  /* color: rgba(var(--vs-danger), 1); */
  color: rgb(29, 23, 23) !important;
  margin: 5px;
  padding: 10px;
  font-size: 15px;
  /* border: 2px solid; */
  border-radius: 5px;
}
.webinar-my-date-picker > div input {
  background: #ffffff;
  -webkit-padding-start: 9%;
  display: block;
  border-radius: 5px;
  // min-height: 10px !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
  color: #000000 !important;
  border-radius: 10px;
}
.no-padd {
  padding: 0% !important;
}
/*stats wise data table css */
.rwd-table {
  width: 98%;
  font-family: "Montserrat";
  margin: 25px auto;
  border-collapse: collapse;
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.05),
    0px 20px 20px rgba(0, 0, 0, 0.05), 0px 30px 20px rgba(0, 0, 0, 0.05);
  tr {
    &:hover {
      background: #f4f4f4;

      td {
        color: #555;
      }
    }
  }
  th,
  td {
    color: #000;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.25);
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    padding: 5px 14px;
    border-collapse: collapse;
  }
  th:first-child,
  td:first-child {
    border-left: 0px solid transparent !important;
  }
  th {
    background: #0044ba;
    color: #fff;
    font-size: 12px;
    &.last {
      border-right: none;
    }
  }
  tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }
  tr:last-child th:last-child {
    border-top-right-radius: 10px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
}
.rwd-table {
    tr td:last-child {
      font-size: 12px;
    }
  }
// @media only screen and (max-width: 1500px) {
//   .ne-table {
//     th {
//       font-size: 13px !important;
//     }
//     .vs-table--tr {
//       font-size: 13px !important;
//     }
//   }
//   .rwd-table {
//     tr td:last-child {
//       font-size: 12px;
//     }
//   }
//   .reportrange-text {
//     font-size: 11px;
//   }
//   .date_filter_heading {
//     font-size: 9px;
//   }
// }
.stat-wise-data-box {
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  padding-inline: 20px;
  align-items: center;
  background-color: #0044ba;
  border-radius: 0 0 15px 15px;
  width: 20%;
  height: 35px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}
.webinar-stats-box {
  color: #000000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  box-sizing: border-box;
  width: 250px;
  height: 82px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  vertical-align: middle;
  margin-bottom: 10%;
  background: #f5f5f5;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  flex-direction: column;
}
.desc-border-box2 p {
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  padding-inline: 20px;
  align-items: center;
  background-color: #0044ba;
  border-radius: 15px 15px 15px 15px;
  width: 20%;
  height: 35px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}
.desc-border-box3 p {
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  padding-inline: 20px;
  align-items: center;
  background-color: #0044ba;
  border-radius: 0px 0px 15px 15px;
  width: 20%;
  height: 35px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}
.wd-second-heading-text {
  text-align: center;
  background: #0044ba;
  border-radius: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  /* identical to box height */
  text-align: center;

  color: #ffffff;
}
/* search criteria */
.wd-criteria-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}
.wd-search-criteria {
  width: 100%;
  background: #ffffff;
  min-height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
/*tree css*/
.visitor_dropdown .vs__dropdown-toggle {
  height: 45px !important;
}
.visitor_dropdown .vs__dropdown-toggle .vs__selected-options span {
  font-weight: 500;
  font-size: 14px;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
}
.a-iconns > .material-icons {
  margin-left: auto;
}
.a-iconns {
  color: #000;
  /* font-weight: 500; */
  font-size: 14px;
  width: 99%;
  display: flex;
  justify-content: flex-end !important;
}
.wd-tree-drop#webinar > .vs-con-dropdown {
  background: #f1ad78;
  border: 1px solid #f38630;
  border-radius: 10px;
  // width: 100%;
}
.wd-tree-drop#webinar-stats > .vs-con-dropdown {
  background: #fae1fe;
  border: 1px solid #de56f4;
  border-radius: 10px;
  width: 100%;
}
.wd-tree-drop#webinar-course > .vs-con-dropdown {
  background: #ffd5d5;
  border: 1px solid #ec6868;
  border-radius: 10px;
  width: 100%;
}
.wd-tree-drop#webinar-city > .vs-con-dropdown {
  background: #eae5fa;
  border: 1px solid #2e0d92;
  border-radius: 10px;
  width: 100%;
}

.wd-tree-drop#webinar-team > .vs-con-dropdown {
  background: #caecc5;
  border: 1px solid #ace4a4;
  border-radius: 10px;
  width: 100%;
}
// .wd-tree-drop#webinar-gm > .vs-con-dropdown {
//   background: #c2fff0;
//   border: 1px solid #00c898;
//   border-radius: 10px;
//   width: 100%;
// }
// .wd-tree-drop#webinar-spoc > .vs-con-dropdown {
//   background: #bcebfa;
//   border: 1px solid #1fabd8;
//   border-radius: 10px;
//   width: 100%;
// }
.wd-tree-drop#webinar-level > .vs-con-dropdown {
  background: #c2ffc8;
  border: 1px solid #00a610;
  border-radius: 10px;
  width: 100%;
}
.wd-tree-drop#webinar-date > .vs-con-dropdown {
  background: #fff9c2;
  border: 1px solid #d4c000;
  border-radius: 10px;
  width: 100%;
}

.wd-tree-drop#webinar-topic > .vs-con-dropdown {
  background: #ffcdf1;
  border: 1px solid #ef22b4;
  border-radius: 10px;
  width: 100%;
}
/* .wd-tree-drop#level_drop > .vs-con-dropdown {
  background: #99eee3;
  border: 1px solid #14d685;
  border-radius: 10px;
  width: 100%;
} */
.wd-tree-drop > .vs-con-dropdown {
  width: 100%;
  min-height: 32px;
  border-radius: 10px;
  padding-inline: 5px;
  color: #000000;
  display: flex !important;
  align-items: center;
}
/*tree css*/
.vs-dropdown--menu {
  width: 300px;
  // height: 500px;
  // overflow: hidden;
}
.filter-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}
.filter-card-wd {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 100%;
}
.wd-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}
.wd-heading {
  background: #0044ba;
  border-radius: 15px 0px;
  width: 233.62px;
  height: 37.8px;
  display: flex;
  justify-content: space-between;
  padding-inline: 1%;
  align-items: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wd-tree-drop > .vs-con-dropdown {
  width: 100%;
  min-height: 32px;
  border-radius: 10px;
  padding-inline: 5px;
  color: #000000;
  display: flex !important;
  align-items: center;
}
// .wd-tree-drop#citys > .vs-con-dropdown {
//   background: #eae5fa;
//   border: 1px solid rgba(46, 13, 146, 0.5);
//   width: 90%;
//   border-radius: 10px;
//   margin-inline: 2%;
// }
.wd-tree-drop#webinar-levels > .vs-con-dropdown {
  background: #C2FFC8;
  border: 1px solid #00A610;
  width: 90%;
  border-radius: 10px;
  margin-inline: 2%;
}
.wd-tree-drop#macc_level_drop > .vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #ec6868;
  width: 90%;
  border-radius: 10px;
  margin-inline: 2%;
}
.wd-tree-drop#webinar-course > .vs-con-dropdown {
  background: #FFD5D5;
  border: 1px solid #EC6868;
  width: 90%;
  border-radius: 10px;
  margin-inline: 2%;
}
.wd-tree-drop#gm > .vs-con-dropdown {
  background: #c2fff0;
  border: 1px solid #00c898;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}
.wd-tree-drop#spoc > .vs-con-dropdown {
  background: rgba(188, 235, 250, 0.69);
  border: 1px solid #1fabd8;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}
.criteria_chip {
  border-radius: 5px;
}
.criteria_chip .vs-chip--close {
  background: transparent !important;
  color: #000000;
}
.criteria_chip .vs-chip--text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #000000;
}
.table-overflow {
    overflow-x: auto;
    // margin-right: 10px !important;
}
// .vs--single .vs__selected {
//   background-color: transparent;
//   border-color: transparent;
//   white-space: nowrap;
//   width: 240px;
//   overflow: hidden;
//   text-overflow: 'ellipsis';
// }
</style>